@import "../../assets/styles/variable.scss";

#redefinirPaciente {

  h1 {
    color: $blue;
  }

  .grid-box {
    grid-gap: 0;

    &.grid-two {
      grid-template-columns: 40% 60%;

      @media screen and (max-width: 991px) {
        grid-template-columns: 1fr;
      }

    }

  }


  .background-gray {
    display: grid;
    align-items: center;
    background: $graydark;

    @media screen and (max-width: 599px) {
      height: auto;
      padding: 2rem 0;
    }

    @media screen and (max-width: 991px) {
      order: 2;
      display: none;
    }

    img {
      max-width: 70%;
      height: auto;
      display: block;
      margin: 0 auto;
    }

  }

  .background-blue-light {
    display: flex;
    align-items: center;
    justify-content: center;

    .form {
      text-align: center;
      padding: 0 4rem;
      width: 100%;

      @media screen and (max-width:425px) {
        padding: 0 2rem;
      }

      img {
        max-width: 280px;
        height: auto;
        margin-top: 20px;
      }

      a {
        color: $white;
        text-decoration: none;
        font-family: $fontPrimaryBold;
        display: block;
        margin-top: 1rem;

        &:hover {
          color: $orange;
        }

      }

      button {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      .termos {
        margin-top: 1rem;

        a {
          font-family: $fontPrimary;
          text-decoration: underline;
          padding-bottom: .5rem;
        }
      }

    }

  }

  .validation {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;


    @media screen and (max-width: 670px) {
      grid-template-columns: 1fr;
    }

    .validator {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      p {
        color: $white;
        margin: 0;
      }

      svg {
        color: #f00;
        padding-right: 5px;

        &.success {
          color: #11AF41;
        }
      }
    }
  }
}
