@import "./variable.scss";
@import "./index.scss";
@import "./normalize.scss";
@import"./devices.scss";

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima-nova-webfont.woff2') format('woff2'),
       url('../fonts/proxima-nova-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Proxima Nova Black';
  src: url('../fonts/proxima-nova-black-webfont.woff2') format('woff2'),
       url('../fonts/proxima-nova-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body, button, input, optgroup, select, textarea {
  font-family: $fontPrimary;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  color: $bluedark;
  background: #f2f2f2;
}

button {
  border: none;
}

ul {
  padding: 0px;
}

h1 {
  color: $bluedark;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

a {
  cursor: pointer;
}

.grid-box {
  display: grid;
  grid-gap: 1rem;
  align-items: center;

  &.grid-four{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.grid-three{
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.grid-two{
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

}

.v-height {
  min-height: 100vh;
  height: 100%;
}

.background-gray {
  background: $gray;
}

.background-blue-dark {
  background: $bluedark;
}

.background-blue-light {
  background: $bluelight;
}

img {
  max-width: 100%;
}

.materializeForm {
  margin: 2rem 0 0rem 0rem;

  input {
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: solid 2px $white;
    padding: .5rem 1rem;
    color: $white;
    margin: 1rem 0;
    outline: none;

    &::placeholder {
      color: $white;
    }

    &:focus {
      border-color: $orange;
    }

  }

  button, a.button {
    width: 100%;
    border: 0;
    border-radius: 1rem;
    height: 45px;
    background: $orange;
    color: $white;
    font-family: $fontPrimaryBold;
    text-transform: uppercase;
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      background: $pink;
      color: $white !important;
    }
  }

  a.button {
    display: flex!important;
    justify-content: center;
    align-items: center;
  }

  .buttonGoogle {
    width: 70%;
    border: 0;
    border-radius: 1rem;
    height: 45px;
    background: $white;
    color: $black;
    font-family: $fontPrimaryBold;
    text-transform: uppercase;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: $red !important;
    }
  }

  .buttonFacebook {
    width: 70%;
    border: 0;
    border-radius: 1rem;
    height: 45px;
    background: $blueFacebook;
    color: $white;
    font-family: $fontPrimaryBold;
    text-transform: uppercase;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background-color: #8f9ef2;
      color: $white !important;
    }
  }

}

.fixed-bottom, .fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

.fixed-top {
  top: 0;
}

main {
  background-color: $gray;
  // background-image: url("../images/grafismo.png");
  // min-height: 100vh;
  height: 100%;
  margin: 1.5rem 0 0 17.1rem;
  padding: 1.2rem;
  transition: margin-left .3s;
  position: relative;

  @media screen and (max-width: $tablet){
    margin-left: 0;
    padding: 2rem 1rem;
  }
}

.main-paciente {
  padding: 1.7rem
}

.logo {
  @media screen and (max-width: 560px) {
    margin: 0 auto;
  }
}

button.toggle {
  position: fixed;
  left: 190px;
  top: 20px;
  z-index: 46;
  background: none;
  border: none;
  outline: none;
  height: 25px;
  width: 30px;
  padding: 0;
  cursor: pointer;

  @media screen and (max-width: 560px) {
    left: 20px;
  }

  i {
    display: block;
    height: 2px;
    position: absolute;
    top: 10px;
    width: 30px;
    background: #fff;
    right: 0px;

    &:before, &:after {
      content: '';
      position: absolute;
      right: 0px;
      width: 30px;
      height: 2px;
      background: #fff;
      backface-visibility: hidden;
    }

    &:before {
      top: -10px;
    }

    &:after {
      bottom: -10px;
    }

  }

}

.card {
  background: $white;
  box-shadow: 0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  word-wrap: break-word;
  background-clip: initial;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .75rem;

  .card-body-graph {
    flex: 1 1 auto;
    padding: 1.5rem;

    h1 {
      text-transform: uppercase;
      margin: 0 .5rem;
    }
  }

  .card-grid-graph {
    display: grid;
    grid-gap: 2rem;
    align-items: center;
    grid-template-columns: 1fr 200px;
    text-align: center;

    @media screen and (max-width: 1390px) {
      grid-template-columns: 1fr 100px;
    }

    @media screen and (max-width: 1170px) {
      grid-template-columns: 1fr;

      img, svg {
        max-width: 200px;
      }
    }
    a {
      margin: .5rem;
    }

    h2 {
      margin-bottom: 0;
    }

    h3 {
      margin-top: 0;
    }
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1.5rem;

    h1 {
      text-transform: uppercase;
      margin: 0 .5rem;
    }
  }

  .card-grid {
    display: grid;
    grid-gap: 2rem;
    align-items: center;
    grid-template-columns: 200px 1fr;
    text-align: center;

    @media screen and (max-width: 1390px) {
      grid-template-columns: 100px 1fr;
    }

    @media screen and (max-width: 1170px) {
      grid-template-columns: 1fr;

      img, svg {
        max-width: 200px;
      }
    }
    a {
      margin: .5rem;
    }

    h2 {
      margin-bottom: 0;
    }

    h3 {
      margin-top: 0;
    }
  }
}

a.button, button.button {
  padding: .5rem 1rem;
  border: 0;
  border-radius: 1rem;
  height: 45px;
  background: #FF8F23;
  color: #FFFFFF;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin: 0 .5rem;

  &.blue {
    background: $blue;
  }

  &.blue-dark {
    background: $bluedark;
  }

  &:hover {
    background: $pink;
  }
}

.container-pass {
  display: flex;
  position: relative;
  align-items: center;

  a {
    color: #fff;
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-top: 0 !important;
  }
}

.app-container {

  &.app-clinic{
    margin-top: 60px;
  }

  &.app-admin{
    margin-top: 60px;
  }

  .MuiTableCell-root {
    font-family: $fontPrimary !important;

    a {
      text-decoration: none;
    }

    button {
      margin: 0 .3rem;
    }

  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $bluedark;
  }

  .MuiButton-containedPrimary {
    background-color: $blue;

    &.red-button {
      background-color: #f00;
    }

    &.green-button {
      background-color: #008000;
    }

    &:hover {
      background-color: $bluedark;
    }
  }

  .MuiButton-containedSecondary {
    background-color: $orange;

    &.red-button {
      background-color: #f00;
    }

    &.green-button {
      background-color: #008000;
    }

    &:hover {
      background-color: $pink;
    }
  }

  @media screen and (max-width: 991px){
    form > div {
      display: block;

      & > * {
        display: flex;
        margin: 20px 0;

        .MuiInputBase-root {
          width: 100%;
        }

      }
    }

    .container-pass a {
      top: 0;
    }

    .MuiInput-underline {
      display: inline-flex;
    }
  }
}

.MuiDialog-container {
  span {
    display: block;
    margin: 1em 0;
  }
}

.termos {
  label {
    display: flex;
    color: $white;
    align-items: baseline;

    .MuiButtonBase-root {
      padding: 0 0 0 9px;
      color: $white;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: transparent !important;
  color: $white !important;
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

button.no-text {
  min-width: 44px;

  .MuiButton-startIcon {
    margin: 0;
  }
}

.flagPhone {
  width: 30px;
  position: absolute;
  top: 5px;
  left: 13px;

  &.outlined {
    top: 13px;
  }
}

.MuiButton-sizeLarge {
  display: inline-flex !important;
  margin: 8px !important;
}

.hidden {
  display: none!important;
}

.MuiOutlinedInput-input {
  color: $bluedark;
}

.MuiOutlinedInput-input :disabled{
  color: $grayletter;
}
