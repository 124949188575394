$blue: #01A3FF; // Thats a sample how you could change a BootStrap variable.
$bluedark: #00546B;
$bluelight: #21ACFC;
$blueFacebook: #4267B2;
$greenWhatsapp: #34af23;
$gray: #F2F2F2;
$graydark: #eaeae9;
$grayblack:#D8D8D8;
$grayletter:#77838F;
$pink: #F4A39D;
$red: #ff2d2d;
$green: #2dff2d;
$orange: #FF8F23;
$white: #FFFFFF;
$black: #000000;
$blacklight: #5c5c5c;
$fontPrimary: 'Proxima Nova';
$fontPrimaryBold: 'Proxima Nova Black';
$fontSecondary: 'Raleway';
$fontWeigth700: 700;
$fontWeigth900: 900;
$separator: 6rem;
$separatorTop: 4rem;
