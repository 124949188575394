.editor{
  border: 1px transparent solid;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI";
  letter-spacing: 1.5px;
  font-size: 95%;
  border-radius: 6px;
  text-align: left;
  line-height: 1.5em;
}

.buttons{
    background-color: #e2e0e0;
    display: block;

    width: 100%;
    margin: 0 auto;
    button{
        cursor: pointer;
        border: none;
        color: #000;
        text-decoration: none;
        outline: none;
        margin: 5px;
    }
}

.DraftEditor-root {
  font-family: "Proxima Nova";
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
}
